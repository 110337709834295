import { Category } from '../services/api/category/category';
import { SubCategory } from '../services/api/sub-category/sub-category';

export const DEFALT_PROFILE_PIC =
  'https://silver-isle-temp.s3.amazonaws.com/couple/images/pc7lc2y0CY';
export const DEFALT_COVER_PIC =
  'https://silver-isle-temp.s3.amazonaws.com/couple/images/59wTcMS0nJ';

export const POOL_ELIGIBLE_PRICE = 40000;

export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_COUPLE = 'ROLE_COUPLE';

// Socail links
export const FB = 'https://www.facebook.com/silveraislegifting';
export const INSTAGRAM =
  'https://www.instagram.com/silveraisle_weddingregistry/';
export const INSTAGRAM_GIFTING =
  'https://www.instagram.com/silveraisle_gifting/';
export const WHATSAPP = 'https://wa.me/9471225 8888';
export const PINTEREST = 'https://www.pinterest.com/silveraislecolombo/';

export const UNDER_5K = 'Under 5K';
export const S1 = '5,000 - 12,000';
export const S2 = '12,000 - 25,000';
export const S3 = '25,000 - 50,000';
export const S4 = 'Over 50,000';

export const HI = 'High to Low';
export const LOW = 'Low to High';

export const TO_TOP = 'To top';
export const TO_BOTTOM = 'To bottom';

export const EGIFT_CARD = 'E-Gift Cards';
export const GIFTING = 'Gifting';

/**
 * The initial requirement was to have delivery charge for e-giftcards.
 * Then afterwards the city wise delivery charge system was introduced.
 * Unless the item is e-giftcard and addres is provided delivery cost is associated with the items
 */
export const DELIVERY_COST = 0;

export const REGISTRY = 'Registry';

export const ALL_SUB_CATEGORY: SubCategory = { name: 'All' };
export const SAME_DAY_DELIVERY: SubCategory = {
  name: 'Same Day Delivery',
  image:
    'https://silver-aisle.s3-ap-southeast-1.amazonaws.com/assets/images/sameday.jpg',
};

export const FLOWERS_SUB_CATEGORY: SubCategory = {
  name: 'Flowers',
  image:
    'https://silver-aisle.s3-ap-southeast-1.amazonaws.com/assets/images/flowers.jpg',
};

export const CAKES_SUB_CATEGORY: SubCategory = {
  name: 'Cakes And Desserts',
  image:
    'https://silver-aisle.s3-ap-southeast-1.amazonaws.com/assets/images/cakes_and_desserts.jpg',
};

export const EXPERIENCES_SUB_CATEGORY: SubCategory = {
  name: 'Experiences',
  image:
    'https://silver-aisle.s3-ap-southeast-1.amazonaws.com/assets/images/experiences.jpg',
};

export const EXPERIENTIAL_DINING_SUB_CATEGORY: SubCategory = {
  name: 'Experiential Dining',
  image:
    'https://silver-aisle.s3-ap-southeast-1.amazonaws.com/assets/images/experiential_dining.jpg',
};

export const THE_CHRISTMAS_COLLOECTION_SUB_CATEGORY: SubCategory = {
  name: 'The Christmas Collection',
  image:
    'https://silver-aisle.s3-ap-southeast-1.amazonaws.com/assets/images/experiential_dining.jpg',
};

export const VIEW_ALL_CATEGORIES: string =
  'https://silver-aisle.s3-ap-southeast-1.amazonaws.com/assets/images/view_all_categories.jpg';

export const EGIFT_CARD_SUB: SubCategory = { name: 'e gift cards' };

export const NEW_ARRIVALS: Category = {
  name: 'New Arrivals',
  url: 'https://gift.silveraisle.com/product-category/new-arrivals/',
};

// Icon name space
export const ICON_SITE = 'site';
export const ICON_SITE_URL = 'https://www.silveraisle.com/assets/svg/site.svg';
