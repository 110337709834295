import { SubCategory } from '../services/api/sub-category/sub-category';
import { MenuItem } from './models/menu-item';
import { SAME_DAY_DELIVERY } from './silver-aisle-consts';

// ------------------ Root level ------------------
const ROOT_NAV = '';
export const ADMIN_NAV = 'admin';
export const COUPLE_NAV = 'couple';

export const SIGN_IN_NAV = 'sign-in';
export const SIGN_UP_NAV = 'sign-up';
export const SIGN_OUT_NAV = 'sign-out';

// ------------------ Couple level ------------------
export const TERMS_AND_CONDITION_NAV = 'terms-and-conditions';
export const REGISTRY_NAV = 'registry';
export const SETTINGS_NAV = 'settings';
export const OUR_STORY_NAV = 'our-story';
export const PRIVACY_NAV = 'privacy';
export const HOME_NAV = 'home';
export const REGISTRY_ID = 'registry-id';

// ------------------ Site level ------------------
export const WEDDING_REGISTRY_NAV = 'wedding-registry';
export const BABY_REGISTRY_NAV = 'baby-registry';
export const ABOUT_NAV = 'about';
export const BRANDS_NAV = 'brands';
export const FIND_NAV = 'find';
export const USER_GUIDE_NAV = 'user-guide';
export const DELIVERY_NAV = 'delivery';
export const CONTACT_US_NAV = 'contact-us';
export const CART_NAV = 'cart';
export const GIFT_NAV = 'gifting';
export const HELP_NAV = 'help';
export const FAQ_NAV = 'faq';
export const PAYMENT_NAV = 'payment';
export const TERMS_NAV = 'terms';
export const BLOG_NAV = 'blog';
export const TESTIMONIALS_NAV = 'testimonials';

export const BRAND_NAME_PATH = 'brand-name';
export const PRODUCT_ID = 'product-id';
export const GIFTING_CATEGORY_PATH = 'gift-category-name';
export const CATEGORY_NAME_PATH = 'category-name';
export const PAGE_QPARAM = 'page';
export const START_QPARAM = 'start';
export const END_QPARAM = 'end';
export const CATEGORIES = 'categories';

export const BS = 'bs';
export const BRA_SUB = 'brand-sub';

export const WS = 'ws';
export const WED_SUB = 'wedding-sub';

// couple
const TERMS_AND_CONDITIONS = 'Terms & Conditions';
const EDIT_BASIC_INFO = 'Edit Basic Info';
const EDIT_OUR_STORY = 'Edit Our Story';
const VIEW_REGISTRY = 'View Registry';
const PRIVACY = 'Privacy';
const DELIVERY = 'Delivery';

const ADD_ITEM_TO_REGISTRY = 'Add item to Registry';
const INSPIRE = 'Inspiration guide ';
const OUR_STORY = 'Our story';
const NOTIFICATIONS = 'Notifications';
const SETTINGS = 'Settings';
const GOLIVE = 'Go live';

// site second line in toolbar
const HOME = 'Home';
const WEDDING_REGISTRY = 'Wedding Registry';
const ABOUT = 'About';
const BRANDS = 'Brands';

// others are generated based on category
const EXPERIENCES = 'Experiences';
const GIFTING = 'Gifting';

const BLOG = 'Blog';
const SIGN_IN = 'Sign in';
const SIGN_UP = 'Sign up';
const SIGN_OUT = 'Sign Out';
const LOGOUT = 'Log out';
const CART = 'Cart';
const FAQ = 'FAQ';
const HELP = 'Help';
const CONTACT_US = 'Contact us';

export const COUPLE_SETTINGS: MenuItem[] = [
  { title: EDIT_BASIC_INFO, url: `/${COUPLE_NAV}/${SETTINGS_NAV}` },
  { title: EDIT_OUR_STORY, url: `/${COUPLE_NAV}/${OUR_STORY_NAV}` },
  { title: DELIVERY, url: `/${COUPLE_NAV}/${DELIVERY_NAV}` },
  { title: PRIVACY, url: `/${COUPLE_NAV}/${PRIVACY_NAV}` },
  {
    title: TERMS_AND_CONDITIONS,
    url: `/${COUPLE_NAV}/${TERMS_AND_CONDITION_NAV}`,
  },
  { title: SIGN_OUT, url: `/${COUPLE_NAV}/${SIGN_OUT_NAV}` },
];

export const ADMIN_HOME_MENU_ITEM = { url: `/${ADMIN_NAV}` };

// Site -> root
export const ROOT_MENU_ITEM: MenuItem = { title: HOME, url: `/` };
export const HOME_MENU_ITEM: MenuItem = { title: HOME, url: `/` };
export const TNC_MENU_ITEM: MenuItem = {
  title: TERMS_AND_CONDITIONS,
  url: `/tnc`,
};

export const WEDDING_REGISTRY_MENU_ITEM: MenuItem = {
  title: WEDDING_REGISTRY,
  url: `/${WEDDING_REGISTRY_NAV}`,
};

// Gifting
export const GIFTING_MENU_ITEM: MenuItem = {
  title: GIFTING,
  url: `https://gift.silveraisle.com`,
};

export const GIFTING_CATEGORIES_MENU_ITEM: MenuItem = {
  url: `https://gift.silveraisle.com`,
};

export const FAQ_MENU_ITEM = {
  title: FAQ,
  url: `https://gift.silveraisle.com/faq/`,
};

export const TESTIMONIALS_ITEM = {
  title: 'Testimonials',
  url: `https://gift.silveraisle.com/testimonials/`,
};
export const HELP_MENU_ITEM: MenuItem = {
  title: HELP,
  url: `https://gift.silveraisle.com/help/`,
};
export const CONTACT_US_MENU_ITEM = {
  title: CONTACT_US,
  url: `https://gift.silveraisle.com/contact-us/`,
};
export const ABOUT_MENU_ITEM = {
  title: ABOUT,
  url: `https://gift.silveraisle.com/about/`,
};
export const BRANDS_MENU_ITEM = { title: BRANDS, url: `/${BRANDS_NAV}` };
export const BLOG_MENU_ITEM = {
  title: BLOG,
  url: `https://gift.silveraisle.com/blog/`,
};
export const CART_MENU_ITEM = { title: CART, url: `/${CART_NAV}` };

export const PRODUCTS_MENU_ITEM = {
  url: `/${ROOT_NAV}/${WEDDING_REGISTRY_NAV}`,
};
export const DELIVERY_MENU_ITEM = {
  title: DELIVERY,
  url: `/${ROOT_NAV}/${DELIVERY_NAV}`,
};
export const FIND_MENU_ITEM = { url: `/${ROOT_NAV}/${FIND_NAV}` };

// Site -> login
export const SIGN_IN_MENU_ITEM: MenuItem = {
  title: SIGN_IN,
  url: `/${SIGN_IN_NAV}`,
};
export const SIGN_UP_MENU_ITEM: MenuItem = {
  title: SIGN_UP,
  url: `/${SIGN_UP_NAV}`,
};
export const LOGOUT_MENU_ITEM = { title: LOGOUT, url: `/${SIGN_OUT_NAV}` };

// Site -> couple
export const VIEW_REGISTRY_MENU_ITEM = {
  title: VIEW_REGISTRY,
  url: `/${COUPLE_NAV}/${REGISTRY_NAV}`,
};
export const GOLIVE_MENU_ITEM = { title: GOLIVE, url: 'couple/go-live' };
export const COUPLE_HOME_MENU_ITEM = {
  url: `/${COUPLE_NAV}/${HOME_NAV}`,
};
export const NOTIFICATIONS_MENU_ITEM = {
  title: NOTIFICATIONS,
  url: '/couple/notifications',
};

export const OUR_STORY_MENU_ITEM = {
  title: OUR_STORY,
  url: 'couple/our-story',
};
export const INSPIRE_MENU_ITEM = {
  title: INSPIRE,
  url: 'couple/inspiration-guide',
};
export const TERMS_MENU_ITEM = {
  title: TERMS_AND_CONDITIONS,
  url: `/${COUPLE_NAV}/${TERMS_AND_CONDITION_NAV}`,
};
export const PRIVICY_MENU_ITEM = {
  title: PRIVACY,
  url: `/${COUPLE_NAV}/${PRIVACY_NAV}`,
};
// couple settings
export const EDIT_BI_MENU_ITEM = {
  title: EDIT_BASIC_INFO,
  url: 'couple/settings',
};
export const EDIT_OS_MENU_ITEM = {
  title: EDIT_OUR_STORY,
  url: OUR_STORY_MENU_ITEM.url,
};
export const PRICACY_MENU_ITEM = { title: PRIVACY, url: PRIVICY_MENU_ITEM.url };

export const SETTINGS_MENU_ITEM = {
  title: SETTINGS,
  children: [
    EDIT_BI_MENU_ITEM,
    EDIT_OS_MENU_ITEM,
    DELIVERY_MENU_ITEM,
    PRICACY_MENU_ITEM,
    TERMS_MENU_ITEM,
  ],
};

// categories
export const KND_MENU_ITEM = { title: 'Kitchen and Dining' };
export const BNB_MENU_ITEM = { title: 'Bed and Bath' };
export const DNF_MENU_ITEM = { title: 'Decor and Furniture' };
export const ELE_MENU_ITEM = { title: 'Home Appliances' };
export const EX_MENU_ITEM = { title: EXPERIENCES };

export const WEDDING_CATEGORIES = [
  'New Arrivals',
  'Kitchen and Dining',
  'Bed and Bath',
  'Home Decor',
  'Home Appliances',
  'Honeymoon Fund and Getaways',
  'Silver Aisle Gift Cards',
  'Experiences',
];

export const SUB_CATEGORIES: SubCategory[] = [
  {
    name: SAME_DAY_DELIVERY.name,
    url: 'https://gift.silveraisle.com/product-category/gifting/same-day-delivery/',
  },
  {
    name: 'Flowers',
    url: 'https://gift.silveraisle.com/product-category/gifting/flowers/',
  },
  {
    name: 'Cakes and Desserts',
    url: 'https://gift.silveraisle.com/product-category/gifting/cakes-and-desserts/',
  },
  {
    name: 'Experiences',
    url: 'https://gift.silveraisle.com/product-category/gifting/experiences/',
  },
  {
    name: 'Experiential Dining',
    url: 'https://gift.silveraisle.com/product-category/gifting/experiential-dining/',
  },
  {
    name: 'Spa Experiences',
    url: 'https://gift.silveraisle.com/product-category/gifting/spa-experiences/',
  },
  {
    name: 'Getaways',
    url: 'https://gift.silveraisle.com/product-category/gifting/getaways/',
  },
  {
    name: 'Luxe hampers',
    url: 'https://gift.silveraisle.com/product-category/gifting/luxe-hampers/',
  },
  {
    name: 'Gourmet Platters and Gift Boxes',
    url: 'https://gift.silveraisle.com/product-category/gifting/gourmet-platters-and-gift-boxes/',
  },
  {
    name: 'Balloons',
    url: 'https://gift.silveraisle.com/product-category/gifting/balloons/',
  },
  {
    name: 'Silver Aisle Gift Cards',
    url: 'https://gift.silveraisle.com/product-category/gifting/silver-aisle-gift-cards/',
  },
];

export const COLLECTION_SUBCATEGORIES = [
  // {
  //   name: SAME_DAY_DELIVERY.name,
  //   url: 'https://gift.silveraisle.com/product-category/gifting/same-day-delivery/',
  // },
  // {
  //   name: 'Luxury Stationery Suites',
  //   url: 'https://gift.silveraisle.com/product-category/gifting/luxury-stationery-suites/',
  // },
  {
    name: 'Weddings',
    url: 'https://gift.silveraisle.com/product-category/weddings/',
  },
  {
    name: 'Birthdays',
    url: 'https://gift.silveraisle.com/product-category/gifting/birthdays/',
  },
  {
    name: 'Anniversaries',
    url: 'https://gift.silveraisle.com/product-category/gifting/anniversaries/',
  },
  {
    name: 'Newborn',
    url: 'https://gift.silveraisle.com/product-category/newborn/',
  },
  {
    name: 'Condolence Flowers',
    url: 'https://gift.silveraisle.com/product-category/condolence-flowers/',
  },
  {
    name: 'Gifts For Her',
    url: 'https://gift.silveraisle.com/product-category/gifting/gifts-for-her/',
  },

  {
    name: 'Gifts For Him',
    url: 'https://gift.silveraisle.com/product-category/gifting/gifts-for-him/',
  },
];

export const ADD_ITEM_TO_REGISTRY_MENU_ITEM = {
  title: ADD_ITEM_TO_REGISTRY,
  // url: BRANDS_NAV,
  children: [
    BRANDS_MENU_ITEM,
    KND_MENU_ITEM,
    BNB_MENU_ITEM,
    DNF_MENU_ITEM,
    ELE_MENU_ITEM,
    EX_MENU_ITEM,
  ],
};

export const MENU_ITEMS: MenuItem[] = [
  ROOT_MENU_ITEM,
  ABOUT_MENU_ITEM,
  BRANDS_MENU_ITEM,
];

export const MENU_CATEGORY: MenuItem[] = [
  GIFTING_MENU_ITEM,
  WEDDING_REGISTRY_MENU_ITEM,
];
